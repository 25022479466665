
$background: #fff9ed;
$mobile: "(max-width: 768px)";
$tablet: "(min-width: 768px)";
$tablet_only: "(min-width: 768px) and (max-width: 1023px)";
$desktop: "(min-width: 1024px)";

@mixin mobile {
  @media #{$mobile} {
    @content;
  }
}

@mixin desktop {
  @media #{$desktop} {
    @content;
  }
}

@mixin tablet {
  @media #{$tablet} {
    @content;
  }
}

@mixin tablet-only {
  @media #{$tablet_only} {
    @content;
  }
}

html, body {
  background-color: $background;
  letter-spacing: 0.3px;
  padding: 1rem;
  font-size: 22px;
  line-height: 1.5;
  font-family: 'Nanum Myeongjo', -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, monospace;
  color: rgba(0, 0, 0, 0.9);
}


a {
  color: black;
}

header,
section {
  max-width: 800px;
  margin: 0 auto;
}

.code {
  font-family: monospace;
  color: #ac2727;
}



.u-shake { animation: shake 0.5s ease-out; }

@keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(15px); }
    40% { transform: translateX(-15px); }
    50% { transform: translateX(15px); }
    60% { transform: translateX(-15px); }
    100% { transform: translateX(0); }
}


@keyframes jiggle-1 { 
  0% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.087, 1, 0, 0, 0, 0, 1, 0, 5, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.087, 1, 0, 0, 0, 0, 1, 0, 5, 0, 0, 1); }
  3.4% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.052, 1, 0, 0, 0, 0, 1, 0, 3.422, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.052, 1, 0, 0, 0, 0, 1, 0, 3.422, 0, 0, 1); }
  4.7% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.035, 1, 0, 0, 0, 0, 1, 0, 2.752, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.035, 1, 0, 0, 0, 0, 1, 0, 2.752, 0, 0, 1); }
  6.81% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.009, 1, 0, 0, 0, 0, 1, 0, 1.704, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.009, 1, 0, 0, 0, 0, 1, 0, 1.704, 0, 0, 1); }
  9.41% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.015, 1, 0, 0, 0, 0, 1, 0, 0.583, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.015, 1, 0, 0, 0, 0, 1, 0, 0.583, 0, 0, 1); }
  10.21% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.02, 1, 0, 0, 0, 0, 1, 0, 0.292, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.02, 1, 0, 0, 0, 0, 1, 0, 0.292, 0, 0, 1); }
  13.61% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.029, 1, 0, 0, 0, 0, 1, 0, -0.616, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.029, 1, 0, 0, 0, 0, 1, 0, -0.616, 0, 0, 1); }
  14.11% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.029, 1, 0, 0, 0, 0, 1, 0, -0.705, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.029, 1, 0, 0, 0, 0, 1, 0, -0.705, 0, 0, 1); }
  17.52% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.021, 1, 0, 0, 0, 0, 1, 0, -1.039, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.021, 1, 0, 0, 0, 0, 1, 0, -1.039, 0, 0, 1); }
  18.72% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.016, 1, 0, 0, 0, 0, 1, 0, -1.061, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.016, 1, 0, 0, 0, 0, 1, 0, -1.061, 0, 0, 1); }
  21.32% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.006, 1, 0, 0, 0, 0, 1, 0, -0.981, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.006, 1, 0, 0, 0, 0, 1, 0, -0.981, 0, 0, 1); }
  24.32% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.003, 1, 0, 0, 0, 0, 1, 0, -0.753, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.003, 1, 0, 0, 0, 0, 1, 0, -0.753, 0, 0, 1); }
  25.23% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.005, 1, 0, 0, 0, 0, 1, 0, -0.67, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.005, 1, 0, 0, 0, 0, 1, 0, -0.67, 0, 0, 1); }
  29.03% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.009, 1, 0, 0, 0, 0, 1, 0, -0.315, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.009, 1, 0, 0, 0, 0, 1, 0, -0.315, 0, 0, 1); }
  29.93% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.009, 1, 0, 0, 0, 0, 1, 0, -0.238, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.009, 1, 0, 0, 0, 0, 1, 0, -0.238, 0, 0, 1); }
  35.54% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.003, 1, 0, 0, 0, 0, 1, 0, 0.103, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.003, 1, 0, 0, 0, 0, 1, 0, 0.103, 0, 0, 1); }
  36.74% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.002, 1, 0, 0, 0, 0, 1, 0, 0.142, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.002, 1, 0, 0, 0, 0, 1, 0, 0.142, 0, 0, 1); }
  41.04% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.002, 1, 0, 0, 0, 0, 1, 0, 0.196, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.002, 1, 0, 0, 0, 0, 1, 0, 0.196, 0, 0, 1); }
  44.44% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.003, 1, 0, 0, 0, 0, 1, 0, 0.171, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.003, 1, 0, 0, 0, 0, 1, 0, 0.171, 0, 0, 1); }
  52.15% { -webkit-transform: matrix3d(1, 0, 0, 0, 0.001, 1, 0, 0, 0, 0, 1, 0, 0.044, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0.001, 1, 0, 0, 0, 0, 1, 0, 0.044, 0, 0, 1); }
  59.86% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.001, 1, 0, 0, 0, 0, 1, 0, -0.03, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.001, 1, 0, 0, 0, 0, 1, 0, -0.03, 0, 0, 1); }
  63.26% { -webkit-transform: matrix3d(1, 0, 0, 0, -0.001, 1, 0, 0, 0, 0, 1, 0, -0.036, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, -0.001, 1, 0, 0, 0, 0, 1, 0, -0.036, 0, 0, 1); }
  75.28% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.006, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.006, 0, 0, 1); }
  85.49% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.007, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.007, 0, 0, 1); }
  90.69% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.005, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.005, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}
